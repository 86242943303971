import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, defineAsyncComponent, watch, nextTick } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import API from '@/api/education/index';
import { useMessage } from '@/hooks/web/useMessage';
import Emitter from '@/eventBus/index';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
export default defineComponent({
  components: {
    Eldialog,
    OrganizeSelect: defineAsyncComponent(() => import('@/components/OrganizeSelect/index.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    CourseFormDialog: defineAsyncComponent(() => import('./CourseFormDialog.vue'))
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeSchool: {
      type: Object,
      default: null
    }
  },
  emits: ['update:visible', 'handleSuccess'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      value: '',
      courseVisible: false,
      schoolInfo: {
        id: null,
        name: '',
        activeValue: null
      },
      classIds: [],
      chargeClassIds: [],
      searchValue: '',
      activeValue: '',
      searchClassValue: '',
      props: {
        multiple: true,
        checkStrictly: false,
        value: 'id',
        label: 'name'
      },
      courseOptions: [],
      classOptions: [],
      ruleForm: null,
      // formData: <Type.ClassFormType>null,
      formData: null,
      title: '新增人员',
      rules: {
        teacherName: [{
          required: true,
          message: '请输入管理者名称',
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '管理者名称不能带有特殊符号'
        }],
        phone: [{
          required: true,
          message: '请输入联系手机号码',
          type: 'string',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请填写正确手机号码'
        }],
        schoolId: [{
          required: true,
          message: '请选择单位'
        }],
        courseIds: [{
          required: true,
          message: '请选择人员日常任务'
        }],
        classIds: [{
          type: 'array',
          required: true,
          message: '请选择负责的岗位类型',
          trigger: 'change'
        }],
        chargeClassIds: [{
          required: true,
          message: '请选择管理的岗位类型'
        }]
      },
      confirm() {
        refData.ruleForm.validate(async valid => {
          if (valid) {
            try {
              let refMsg = '';
              let refFlag = 0;
              if (props.info === null) {
                refData.formData.chargeClassIds = refData.formData.type == 1 ? [] : refData.formData.chargeClassIds;
                const {
                  msg,
                  code
                } = await API.teacherAdd(refData.formData);
                refMsg = msg;
                refFlag = code;
              } else {
                refData.formData.chargeClassIds = refData.formData.type == 1 ? [] : refData.formData.chargeClassIds;
                const {
                  msg,
                  code
                } = await API.teacherUpdate({
                  teacherId: props.info.teacherId,
                  ...refData.formData
                });
                refMsg = msg;
                refFlag = code;
              }
              if (refFlag !== 0) throw refMsg;
              message.success('成功');
              emit('update:visible', false);
              emit('handleSuccess');
              Emitter.emit('updateOrg');
              Emitter.emit('handleShow');
            } catch (error) {
              message.error(error);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleClassClick(data) {
        refData.formData.chargeClassIds = data.id;
      },
      // 选择单位回调
      handleDoubleClick(data) {
        refData.formData.courseIds = [];
        refData.formData.chargeClassIds = [];
        refData.formData.classIds = [];
        refData.classIds = [];
        refData.chargeClassIds = [];
        if (data.type === 'school') {
          refData.formData.schoolId = data.id;
          refData.schoolInfo.id = data.id;
          refData.schoolInfo.name = data.name;
          // 获取课程信息
          getCourse(refData.formData.schoolId);
          // 获取单位信息
          getClass(refData.formData.schoolId);
        } else {
          refData.schoolInfo.id = null;
          refData.schoolInfo.name = '';
          message.error('请选择单位');
        }
      },
      // 课程添加
      addCourse() {
        if (refData.schoolInfo.id === null) {
          message.error('请选择单位');
          return;
        }
        refData.courseVisible = true;
      },
      // 课程添加成功回调
      courseHandleSuccess() {
        // 获取课程信息
        getCourse(refData.formData.schoolId);
      }
    });
    const defaultPassword = computed(() => {
      var _state$userInfo;
      return (_state$userInfo = state.userInfo) === null || _state$userInfo === void 0 ? void 0 : _state$userInfo.defaultPassword;
    });
    watch(() => refData.classIds, val => {
      refData.formData.classIds = [];
      refData.classIds.forEach(item => {
        if (item.length === 3) {
          refData.formData.classIds.push({
            classId: item[2]
          });
        }
      });
    });
    watch(() => refData.chargeClassIds, val => {
      if (refData.formData.type === 2) {
        refData.formData.chargeClassIds = [];
        refData.chargeClassIds.forEach(item => {
          if (item.length === 3) {
            refData.formData.chargeClassIds.push({
              classId: item[2]
            });
          }
        });
      } else {
        refData.formData.chargeClassIds = [];
      }
    });
    function getData() {
      Object.keys(props.info).forEach(key => {
        if (refData.formData[key] !== undefined) {
          refData.formData[key] = props.info[key];
        }
      });
      //单位组件复现
      refData.schoolInfo.id = props.info.schoolId;
      refData.schoolInfo.name = props.info.schoolName;
      refData.searchValue = props.info.schoolName;
      // 处理课程数据
      refData.formData.courseIds = [];
      props.info.courseIds.forEach(res => {
        res && refData.formData.courseIds.push(res.courseId);
      });
      // 处理岗位类型数据
      refData.classIds = [];
      props.info.classIds.forEach(res => {
        if (res) {
          refData.classIds.push(res.classId);
          nextTick(() => {
            refData.formData.classIds.push({
              classId: res.classId
            });
          });
        }
      });
      refData.chargeClassIds = [];
      props.info.chargeClassIds.forEach(res => {
        if (res) {
          refData.chargeClassIds.push(res.classId);
          nextTick(() => {
            refData.formData.chargeClassIds.push({
              classId: res.classId
            });
          });
        }
      });
      getClass(refData.formData.schoolId);
      getCourse(refData.formData.schoolId);
    }
    //轮询判断是否禁用
    function handlePolling(data) {
      if (data.children && data.children.length) {
        data.children = data.children.map(val => {
          return {
            ...val,
            disabled: !(val.children && val.children.length)
          };
        });
      }
      ;
      return data;
    }
    ;
    // 获取岗位类型
    async function getClass(schoolId) {
      try {
        const {
          code,
          data,
          msg
        } = await API.schoolTree({
          schoolId
        });
        if (code !== 0) throw msg;
        refData.classOptions = data.map(item => {
          item = handlePolling(item);
          return {
            ...item,
            disabled: !(item.children && item.children.length)
          };
        });
        console.log(data, 'data');
      } catch (error) {
        message.error(error);
      }
    }
    async function getCourse(schoolId) {
      try {
        const {
          code,
          data,
          msg
        } = await API.courseList({
          schoolId
        });
        if (code !== 0) throw msg;
        refData.courseOptions = data;
      } catch (error) {
        message.error(error);
      }
    }
    function init() {
      refData.formData = {
        teacherName: null,
        phone: null,
        sex: 1,
        schoolId: null,
        type: 1,
        chargeClassIds: [],
        courseIds: [],
        classIds: [],
        remark: null
      };
      refData.searchValue = '';
      refData.classIds = [];
      refData.chargeClassIds = [];
    }
    // 初始化表单
    init();
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, val => {
      if (val) {
        init();
        if (props.info !== null) {
          refData.title = '修改人员';
          nextTick(() => {
            init();
            refData.ruleForm.clearValidate();
            getData();
          });
        } else {
          refData.title = '新增人员';
          nextTick(() => {
            init();
            refData.searchValue = props.activeSchool.name;
            refData.formData.schoolId = props.activeSchool.id;
            refData.schoolInfo.id = props.activeSchool.id;
            refData.schoolInfo.activeValue = props.activeSchool.activeValue;
            refData.schoolInfo.name = props.activeSchool.name;
            refData.activeValue = props.activeSchool.activeValue;
            props.activeSchool.id && getClass(props.activeSchool.id);
            props.activeSchool.id && getCourse(props.activeSchool.id);
            refData.ruleForm.clearValidate();
          });
        }
      }
    }, {
      immediate: true
    });
    const [onConfirm] = useDebounce(refData.confirm, 300);
    return {
      ...toRefs(refData),
      show,
      defaultPassword,
      onConfirm
    };
  }
});